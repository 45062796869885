import Image from 'next/image';
import Link from 'next/link';
import { Popover, Transition } from '@headlessui/react';
import { useGlobalContext } from '../lib/global-context';
import { forwardRef } from 'react';

// eslint-disable-next-line react/display-name
const MenuLink = forwardRef((props, ref) => {
    let { href, children, ...rest } = props;
    return (
        <Link href={href}>
            <a ref={ref} {...rest}>
                {children}
            </a>
        </Link>
    );
});
function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function NavbarMobile() {
    const { state } = useGlobalContext();
    const global = state;
    const { panels } = global.navigation;

    return (
        <div id="mobile-nav" className="flex flex-grow justify-end lg:hidden">
            <Popover>
                <Popover.Button className="navbar-menu" title="Navigation">
                    <div></div>
                    <div></div>
                    <div></div>
                </Popover.Button>
                <Popover.Overlay className="fixed inset-0 bg-black opacity-60" />
                <Popover.Panel className="z-50  w-full absolute transition-all transform mx-auto  overflow-hidden inset-x-0">
                    {/* secondary nav */}
                    <div
                        id="small-nav"
                        className="relative flex flex-row sm:flex-col flex-wrap justify-start p-8 m-4 shadow-lg rounded-xl bg-white"
                    >
                        {panels.map((p) => (
                            <div
                                key={p.id}
                                className={`flex flex-col px-4  ${
                                    p.sections.length ? 'pb-8' : 'pb-2'
                                } basis-full sm:basis-1/2`}
                            >
                                <h3 className="text-sm text-trust-900 font-bold uppercase">
                                    {p.sections.length == 0 && (
                                        <Popover.Button as={MenuLink} href={p.link.link}>
                                            {p.link.label}
                                        </Popover.Button>
                                    )}
                                    {p.sections.length > 0 && p.link.label}
                                </h3>
                                {p.sections.length > 0 &&
                                    p.sections.map((s) => (
                                        <div key={s.id}>
                                            {s.title && (
                                                <h3 className="text-xs text-maturity font-bold uppercase pt-6">
                                                    {s.title}
                                                </h3>
                                            )}
                                            <ul role="list">
                                                {s.links.map((item) => (
                                                    <li key={item.id.toString()} className="flow-root">
                                                        <Popover.Button
                                                            as={MenuLink}
                                                            href={item.link}
                                                            className=" flex items-center text-medium text-default hover:text-trust-500 transition duration-150 ease-in-out py-1"
                                                        >
                                                            <span className="ml-0">{item.label}</span>
                                                        </Popover.Button>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                            </div>
                        ))}
                        <div className="flex border-t border-maturity-50 flex-col mt-4 sm:border-none flex-grow">
                            {global.secondaryNavigation.map((nav) => (
                                <div key={`secnav-${nav.id}`} className="flex basis-full pt-4">
                                    <Popover.Button
                                        as={MenuLink}
                                        key={nav.id}
                                        href={nav.link}
                                        className="px-4 py-2 text-trust opacity-75 text-sm uppercase"
                                    >
                                        {nav.label}
                                    </Popover.Button>
                                </div>
                            ))}
                        </div>
                    </div>
                </Popover.Panel>
            </Popover>
        </div>
    );
}
