import { useGlobalContext } from '../lib/global-context';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import NewsletterSignup from './newsletter-signup-v2';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export default function Footer({ className }) {
    const { state, dispatch } = useGlobalContext();
    const router = useRouter();

    const global = state;
    const links = [
        ...global.navigation.panels.filter((p) => p.sections.length == 0).map((p) => p.link),
        ...global.footerLinks
    ];
    const sections = global.navigation.panels.filter((p) => p.sections.length > 0);
    const toggleCookieSettings = () => {
        dispatch({ type: 'TOGGLE_CONSENT', payload: true });
    };
    return (
        <footer>

            <div className="relative">
                <div className="relative bg-footer">
                    <div className="container pt-[4rem] pb-8 px-10 xl:px-2">
                        {router.query.slug !== 'contact' && router.query.slug !== 'get-a-quote' && (
                            <div className="bg-white bg-opacity-5 border-b mb-4 border-white border-opacity-20 w-full relative flex py-8">
                                <GoogleReCaptchaProvider
                                    reCaptchaKey={process.env.NEXT_PUBLIC_RECAPCHA_SITE_KEY}
                                    language="en"
                                    scriptProps={{
                                        async: false,
                                        defer: false,
                                        appendTo: 'head'
                                    }}
                                >
                                    <NewsletterSignup />
                                </GoogleReCaptchaProvider>
                            </div>
                        )}

                        <div className="flex flex-col md:flex-row text-white">
                            {/* socials */}
                            <div className="flex flex-col pr-10 pt-8">
                                <span className="font-semibold text-sm pb-4">FOLLOW US:</span>
                                <div className="icons">
                                    {global.company.facebook && (
                                        <Link href={global.company.facebook}>
                                            <a rel="noreferrer" target="_blank" title="Follow us on Facebook">
                                                <i className="flaticon-facebook mr-3 text-3xl opacity-50 hover:opacity-100 transition duration-300"></i>
                                            </a>
                                        </Link>
                                    )}
                                    {global.company.instagram && (
                                        <Link href={global.company.instagram}>
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                title="Follow us on Instagram"
                                            >
                                                <i className="flaticon-instagram mr-3 text-3xl opacity-50 hover:opacity-100 transition duration-300"></i>
                                            </a>
                                        </Link>
                                    )}
                                    {global.company.youtube && (
                                        <Link href={global.company.youtube}>
                                            <a rel="noreferrer" target="_blank" title="Follow us on YouTube">
                                                <i className="flaticon-youtube mr-3 text-3xl opacity-50 hover:opacity-100 transition duration-300"></i>
                                            </a>
                                        </Link>
                                    )}
                                    {global.company.linkedIn && (
                                        <Link href={global.company.linkedIn}>
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                title="Follow us on Linked-In"
                                            >
                                                <i className="flaticon-linkedin mr-3 text-3xl opacity-50 hover:opacity-100 transition duration-300"></i>
                                            </a>
                                        </Link>
                                    )}
                                    {global.company.twitter && (
                                        <Link href={global.company.twitter}>
                                            <a rel="noreferrer" target="_blank" title="Follow us on Twitter">
                                                <i className="flaticon-twitter mr-3 text-3xl opacity-50 hover:opacity-100 transition duration-300"></i>
                                            </a>
                                        </Link>
                                    )}
                                </div>
                            </div>

                            {/* navigation */}
                            <div className="flex flex-row flex-wrap pt-10 md:pt-8">
                                {sections.map((p) => (
                                    <div
                                        key={`section-panel-${p.id}`}
                                        className="flex flex-col basis-full sm:basis-1/2 xl:basis-auto pr-4 pb-4"
                                    >
                                        <span className="text-sm pb-0 font-semibold">{p.link.label}</span>
                                        {p.sections.map((s) => (
                                            <span
                                                key={`footer-section-${p.id}-${s.id}`}
                                                className="flex flex-col"
                                            >
                                                {s.links.map((item) => (
                                                    <Link href={item.link} key={item.id.toString()}>
                                                        <a className="py-1 text-sm text-white opacity-50 hover:opacity-100 hover:text-trust-200 transition duration-150 ease-in-out">
                                                            <span className="ml-0">{item.label}</span>
                                                        </a>
                                                    </Link>
                                                ))}
                                            </span>
                                        ))}
                                    </div>
                                ))}
                                <div
                                    key="no-section-panels"
                                    className="flex flex-col xl:basis-auto pr-4 pb-4"
                                >
                                    <span className="text-sm pb-0 font-semibold">Links</span>
                                    {links.map((p) => (
                                        <Link key={`section-panel-${p.id}`} href={p.link}>
                                            <a className="flex text-sm pb-2 opacity-50 hover:opacity-100 hover:text-trust-200">
                                                {p.label}
                                            </a>
                                        </Link>
                                    ))}

                                    <button
                                        type="button"
                                        onClick={() => toggleCookieSettings()}
                                        className="flex text-sm pb-2 opacity-50 hover:opacity-100 hover:text-trust-200"
                                    >
                                        Cookie Settings
                                    </button>
                                </div>
                            </div>
                            {/* address */}
                            <div className="flex flex-col text-left text-sm items-start mt-10 xl:mt-0 md:text-right md:items-end flex-grow pt-8">
                                <Link href="/" className="logo w-1/5">
                                    <div className="relative h-10 w-[8.5rem]">
                                        <Image
                                            src={global.company.logo.data.attributes.url}
                                            alt=""
                                            className="w-full h-10 object-cover"
                                            width={150}
                                            height={40}
                                        ></Image>
                                    </div>
                                </Link>

                                <span className="font-semibold text-sm pt-4">Address</span>
                                <span className="text-sm opacity-50" dangerouslySetInnerHTML={{ __html: global.company.address }}></span>
                                {/* <span className="text-sm pt-4">Working Hours</span>
                                <span>{global.company.workingHours}</span> */}
                                {global.company.contactInfo.map((c) => (
                                    <div key={c.id}>
                                        <span className="font-semibold text-sm pt-4">{c.title}</span>
                                        <Link href={`tel:${c.phone}`}>
                                            <a className="text-sm opacity-50 hover:opacity-100 block">{c.phone}</a>
                                        </Link>
                                        <Link href={`mailto:${c.email}`}>
                                            <a className="text-sm opacity-50 hover:opacity-100 block">{c.email}</a>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="w-full p-2 text-xs text-left text-white">

                            © Copyright 2022 ARKA Smart Parcel Locker

                        </div>
                    </div>
                </div>
            </div>

        </footer>
    );
}
