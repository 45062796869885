import { useGlobalContext } from '../lib/global-context';
import { useI18nContext } from '../lib/i18n-context';
import Link from 'next/link';
import { Popover } from '@headlessui/react';
import { forwardRef } from 'react';
import { useRouter } from 'next/router';
// eslint-disable-next-line react/display-name
const MenuLink = forwardRef((props, ref) => {
    let { href, locale, children, ...rest } = props;
    return (
        <Link href={href} locale={locale}>
            <a ref={ref} {...rest}>
                {children}
            </a>
        </Link>
    );
});

export default function LanguageChoice() {
    const { state } = useGlobalContext();
    const router = useRouter();
    const i18n = useI18nContext();

    const global = state;

    const globalLanguage = global.languages.find((l) => {
        return l.code === router.locale;
    });

    const menu = i18n.languages.data.map((lg) => {
        if (i18n.originalSlug && i18n.originalSlug === 'home') {
            lg.attributes.slug = '/';
        }
        const globalLg = global.languages.find((l) => {
            return l.code === lg.attributes.locale;
        });
        return {
            id: lg.attributes.id,
            name: globalLg ? globalLg.name : lg.attributes.locale,
            locale: lg.attributes.locale,
            slug: lg.attributes.slug
        };
    });

    if (menu.length <= 1) {
        return '';
    }

    return (
        <Popover className="relative">
            {!menu.length && (
                <Popover.Button className="p-2 text-trust opacity-75 text-xs uppercase bg-trust-500 rounded-lg disabled">
                    {globalLanguage.name}
                </Popover.Button>
            )}
            {menu.length > 0 && (
                <>
                    <Popover.Button className="p-2 text-white opacity-75 text-xs uppercase bg-trust-500 rounded-lg">
                        {globalLanguage.name}
                    </Popover.Button>
                    <Popover.Panel className="absolute z-10 p-2 bg-white rounded-lg right-0 px-4">
                        <div className="flex flex-col">
                            <>
                                {menu.map((link) => (
                                    <Popover.Button
                                        as={MenuLink}
                                        key={`language-${link.id}`}
                                        href={link.slug}
                                        locale={link.locale}
                                        className="py-2 capitalize ui-active:bg-blue-500 ui-active:text-white ui-not-active:bg-white ui-not-active:text-black"
                                    >
                                        {link.name}
                                    </Popover.Button>
                                ))}
                            </>
                        </div>
                    </Popover.Panel>
                </>
            )}
        </Popover>
    );
}
