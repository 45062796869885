import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router'
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { useState, useEffect, Fragment } from 'react';
import { useGlobalContext } from '../lib/global-context';
import NavbarMobile from './navbar-mobile';
import Photo from './photo';
import LanguageChoice from './language-choice';
import { forwardRef } from 'react';

// eslint-disable-next-line react/display-name
const MenuLink = forwardRef((props, ref) => {
    let { href, children, ...rest } = props;
    return (
        <Link href={href}>
            <a ref={ref} {...rest}>
                {children}
            </a>
        </Link>
    );
});

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function Navbar() {

    const { state } = useGlobalContext();
    const router = useRouter();

    const global = state;
    const { panels } = global.navigation;
    const [scrolled, setScrolled] = useState(false);
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [isTop, setIsTop] = useState(true);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            let st = window.pageYOffset || document.documentElement.scrollTop;

            if (st > lastScrollTop) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }

            setLastScrollTop(st < 0 ? 0 : st);
            const offset = window.scrollY;
            setIsTop(offset > 200 ? false : true);
        });
    }, [lastScrollTop]);
    return (
        <div
            className={`fixed z-50 w-full top-0 ${scrolled ? 'scrolled' : ''} ${!isTop ? 'bg-black bg-opacity-80 shadow-lg' : ''
                }`}
        >
            <div className="flex flex-row justify-between max-w-screen-xl mx-auto px-4 pt-2 pb-4 sm:px-6 lg:px-2">
                <Link key="el-1" href="/">
                    <a className={`flex logo items-stretch justify-items-stretch w-40 lg:w-[14%] ml-0 ${router.pathname === '/' ? '2xl:ml-40 3xl:ml-10 4xl:ml-0' : ''}`}>
                        <Photo
                            image={global.company.logo}
                            layout="intrinsic"
                            className="object-cover"
                        ></Photo>
                    </a>
                </Link>

                <NavbarMobile></NavbarMobile>
                <div className="hidden lg:flex flex-col">
                    {/* secondary nav */}
                    <div id="small-nav" className="relative flex flex-grow justify-end pb-1">
                        {global.secondaryNavigation.map((nav) => (
                            <Link key={nav.id.toString()} href={nav.link}>
                                <a className="p-2 text-white hover:text-trust opacity-75 text-xs uppercase">{nav.label}</a>
                            </Link>
                        ))}
                        <LanguageChoice />
                    </div>

                    {/* main nav */}
                    <div id="main-nav" className="flex flex-grow justify-end items-center">
                        {panels.map((p, index) => (
                            <Popover key={p.id.toString()}>
                                {({ open }) => (
                                    <>
                                        {/* REGLUAR LINKS */}
                                        {p.sections.length == 0 && (
                                            <>
                                                {p.style === 'button' && (
                                                    <Link key={p.link.id} href={p.link.link}>
                                                        <a className="group inline-flex text-white !border-2 rounded-[0.25rem] py-2 px-6 !border-white uppercase !text-xs font-semibold">
                                                            <span>{p.link.label}</span>
                                                        </a>
                                                    </Link>
                                                )}
                                                {p.style === 'link' && (
                                                    <Link key={p.link.id} href={p.link.link}>
                                                        <a className="group inline-flex text-white py-2 pr-4 !text-sm">
                                                            <span>{p.link.label}</span>
                                                        </a>
                                                    </Link>
                                                )}
                                            </>
                                        )}

                                        {/* PANELS */}
                                        {p.sections.length > 0 && (
                                            <>
                                                <Popover.Button
                                                    className={classNames(
                                                        open ? 'text-trust' : 'text-white',
                                                        'relative group inline-flex items-center rounded-md text-sm font-medium hover:text-trust focus:outline-none mr-4 py-2'
                                                    )}
                                                >
                                                    <span>{p.link.label}</span>
                                                    {p.sections.length > 0 && (
                                                        <ChevronDownIcon
                                                            className={classNames(
                                                                open ? 'text-trust' : 'text-white',
                                                                'ml-1 h-5 w-5 group-hover:text-trust opacity-50'
                                                            )}
                                                            aria-hidden="true"
                                                        />
                                                    )}
                                                </Popover.Button>

                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-200"
                                                    enterFrom="opacity-0 scale-80 -translate-y-6"
                                                    enterTo="opacity-100 scale-100 translate-y-0"
                                                    leave="transition ease-in duration-150"
                                                    leaveFrom="opacity-100  scale-100 translate-y-0"
                                                    leaveTo="opacity-0  scale-60 -translate-y-4"
                                                >
                                                    <Popover.Panel
                                                        className={` z-10 transition-all transform mx-auto shadow-lg mt-2 rounded-[0.25rem] overflow-hidden ${p.suggestions.length > 0
                                                            ? 'max-w-screen-xl absolute w-[96%] inset-x-0 mx-auto'
                                                            : 'absolute'
                                                            }`}
                                                    >
                                                        <div
                                                            className="absolute inset-0 flex"
                                                            aria-hidden="true"
                                                        >
                                                            <div
                                                                className={`bg-white ${p.suggestions.length > 0
                                                                    ? 'w-1/2'
                                                                    : 'w-full'
                                                                    }`}
                                                            />
                                                            {p.suggestions.length > 0 && (
                                                                <div className="w-1/2 bg-gray-50" />
                                                            )}
                                                        </div>
                                                        <div
                                                            className={`relative mx-auto grid grid-cols-1 ${p.suggestions.length > 0
                                                                ? 'lg:grid-cols-2'
                                                                : ''
                                                                }`}
                                                        >
                                                            <nav
                                                                className={` ${p.suggestions.length > 0
                                                                    ? 'menuWithSuggestions'
                                                                    : 'standalone'
                                                                    }`}
                                                                aria-labelledby="solutions-heading"
                                                            >
                                                                {p.link.label && (
                                                                    <h2
                                                                        id="solutions-heading"
                                                                        className="sr-only"
                                                                    >
                                                                        {p.link.label} menu
                                                                    </h2>
                                                                )}
                                                                {p.sections.map((s) => (
                                                                    <div key={s.id.toString()}>
                                                                        {s.title && (
                                                                            <h3 className="text-xs text-trust-900 font-bold uppercase pb-2">
                                                                                {s.title}
                                                                            </h3>
                                                                        )}
                                                                        <ul role="list">
                                                                            {s.links.map((item) => (
                                                                                <li
                                                                                    key={item.id.toString()}
                                                                                    className="flow-root"
                                                                                >
                                                                                    <Popover.Button
                                                                                        as={MenuLink}
                                                                                        href={item.link}
                                                                                        className=" flex items-center text-medium text-default hover:text-trust-500 transition duration-150 ease-in-out"
                                                                                    >
                                                                                        <span className="ml-0">
                                                                                            {item.label}
                                                                                        </span>
                                                                                    </Popover.Button>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                ))}
                                                                {p.bottomLinks.length > 0 && (
                                                                    <div className="bottomLinks">
                                                                        {p.bottomLinks.map((btlinks) => (
                                                                            <Popover.Button
                                                                                key={`btmLinks-${p.id}-${btlinks.id}`}
                                                                                as={MenuLink}
                                                                                href={btlinks.link}
                                                                                className="flex items-center text-medium text-trust underline hover:text-default hover:no-underline transition duration-150 ease-in-out"
                                                                            >
                                                                                <span className="ml-0">
                                                                                    {btlinks.label}
                                                                                </span>
                                                                            </Popover.Button>
                                                                        ))}
                                                                    </div>
                                                                )}
                                                            </nav>

                                                            {/* suggestions */}
                                                            {p.suggestions.length > 0 && (
                                                                <div className="bg-gray-50 px-8 py-8 lg:py-12 xl:pr-12">
                                                                    <div>
                                                                        <ul
                                                                            role="list"
                                                                            className="flex flex-row"
                                                                        >
                                                                            {p.suggestions.map((sg) => (
                                                                                <li
                                                                                    key={sg.id}
                                                                                    className="flow-root"
                                                                                >
                                                                                    <div className="flex-shrink-0 sm:block">
                                                                                        <div className="h-32 w-64 mr-2 relative">
                                                                                            <Popover.Button
                                                                                                as={MenuLink}
                                                                                                href={
                                                                                                    sg.link
                                                                                                        .link
                                                                                                }
                                                                                                className="flex"
                                                                                                target={
                                                                                                    sg.link
                                                                                                        .target
                                                                                                }
                                                                                            >
                                                                                                <Image
                                                                                                    className="rounded-md object-cover"
                                                                                                    src={
                                                                                                        sg
                                                                                                            .image
                                                                                                            .data
                                                                                                            .attributes
                                                                                                            .formats
                                                                                                            .thumbnail
                                                                                                            .url
                                                                                                    }
                                                                                                    alt=""
                                                                                                    layout="fill"
                                                                                                ></Image>
                                                                                            </Popover.Button>
                                                                                        </div>
                                                                                        <div className="min-w-0 flex-1 pt-1 pb-4">
                                                                                            <h4 className="truncate text-base font-semibold normal-case">
                                                                                                {sg.title}
                                                                                            </h4>
                                                                                        </div>
                                                                                    </div>
                                                                                    <Popover.Button
                                                                                        as={MenuLink}
                                                                                        href={sg.link.link}
                                                                                        className="cursor-pointer text-action hover:text-action-900 uppercase text-xs font-semibold"
                                                                                        target={
                                                                                            sg.link.target
                                                                                        }
                                                                                    >
                                                                                        <ChevronRightIcon
                                                                                            className="m-0 p-0 h-6 w-6 inline group-hover:text-action-900"
                                                                                            aria-hidden="true"
                                                                                        />
                                                                                        <span>
                                                                                            {sg.link.label}
                                                                                        </span>
                                                                                    </Popover.Button>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Popover.Panel>
                                                </Transition>
                                            </>
                                        )}
                                    </>
                                )}
                            </Popover>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
