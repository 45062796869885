import React, { useState, useCallback } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

function NewsletterSignup() {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [messageSent, setMessageSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [pending, setPending] = useState(false);

    const handleSubmit = useCallback(
        async (event) => {
            event.preventDefault();
            setPending(true);
            const captchaAvailable = executeRecaptcha;
            let data = {};

            try {
                const gReCaptchaToken = captchaAvailable ? await executeRecaptcha('newsletter') : null;

                if (gReCaptchaToken) {
                    const scoreResponse = await fetch('/api/captcha', {
                        headers: { 'Content-Type': 'application/json' },
                        method: 'post',
                        body: JSON.stringify({ token: gReCaptchaToken })
                    });
                    const scoreResult = await scoreResponse.json();
                    data.score = scoreResult.score;
                    // console.log('score', data.score);
                    if (data.score < 0.5) {
                        setErrorMessage(
                            'Robot verification failed! Please try again to restart the verification process. This is a security measure to prevent automated spam and ensure a better user experience'
                        );
                        setMessageSent(false);
                        setPending(false);
                        return;
                    }
                } else {
                    data.score = -1;
                }

                Array.from(event.target.elements).forEach((el) => {
                    if (el.name) {

                        if (el.name.includes('[]')) {
                            if (el.checked) {
                                const newName = el.name.slice(0, -2);
                                if (!data[newName]) {
                                    data[newName] = [];
                                }
                                data[newName].push(el.value);
                            }
                        } else {
                            data[el.name] = el.value;
                        }
                    }
                });


                // API endpoint where we send form data.
                const response = await fetch(`https://europe-west1-arka-robot.cloudfunctions.net/newsletter-signup`, {
                    headers: { 'Content-Type': 'application/json' },
                    method: 'POST',
                    body: JSON.stringify(data)
                });

                const result = await response.json();
                if (response.status !== 200) {
                    setErrorMessage(result.error);
                    setMessageSent(false);
                } else {
                    setErrorMessage('');
                    setMessageSent(true);
                }

                setPending(false);

            } catch (e) {
                console.log(e);
                setErrorMessage(e.message);
                setMessageSent(false);
                setPending(false);
            }
        },
        [executeRecaptcha]
    );

    return (
        <div className="w-full">

            <div className="flex flex-col md:flex-row gap-4 justify-start px-0 lg:px-20">

                {messageSent && (
                    <>
                        <div className="flex flex-col w-full justify-center items-center py-8 lg:py-20 px-8 lg:px-0 text-center">
                            <h3 className="text-3xl lg:text-4xl font-semibold text-trust-500 pb-4">Please confirm your email address</h3>
                            <p className="pb-4">Thank you for subscribing to our newsletter! We&apos;re thrilled to have you on board.</p>
                            <p className="pb-4">To complete your subscription, please confirm your email address by clicking the confirmation link we just sent to your inbox. If you have any questions, please feel free to reach out to us.</p>
                            <p className="pb-4">We look forward to keeping you updated with our latest news and updates!</p>
                        </div>
                    </>
                )}
                {!messageSent && (
                    <>
                        <div className="flex flex-col basis-1 lg:basis-1/2 py-8 px-8 lg:px-0 items-left">
                            <h2 className="text-3xl lg:text-5xl font-bold text-white pb-4">Sign up to our Newsletter</h2>
                            <ul >
                                <li className="flex justify-start items-left"><i className="flaticon-right-arrow text-2xl text-white mt-1"></i><span className="p-2 text-white">Stay tuned to ARKA&apos;s News & Insights</span></li>
                                <li className="flex justify-start items-left"><i className="flaticon-right-arrow text-2xl text-white mt-1"></i><span className="p-2 text-white">Great articles on industrial automation and parcel lockers</span></li>
                                <li className="flex justify-start items-left"><i className="flaticon-right-arrow text-2xl text-white mt-1"></i><span className="p-2 text-white">Get access to exclusive news about innovation</span></li>
                            </ul>
                        </div>
                        <div className="flex flex-col basis-1 lg:basis-1/2 lg:-my-8 px-8 py-10  rounded-[0.25rem] relative text-white text-center items-center justify-center">
                            {pending && (<>Please wait ...</>)}
                            {!pending && (<>

                                <form onSubmit={handleSubmit} className="space-y-4 md:space-y-8 items-center text-left w-full">
                                    <div className="flex">
                                        <input
                                            type="text"
                                            name="fullname"
                                            className="shadow-sm text-white bg-white bg-opacity-10 border rounded-[0.25rem] placeholder:uppercase placeholder:text-xs placeholder:font-semibold focus:ring-primary-500 focus:border-primary-500 block w-full p-3 border-white border-opacity-20"
                                            placeholder="Your name"
                                        />
                                    </div>
                                    <div className="flex">
                                        <input
                                            type="email"
                                            name="email"
                                            required
                                            className="shadow-sm text-white  bg-white bg-opacity-10 border rounded-[0.25rem] placeholder:uppercase placeholder:text-xs placeholder:font-semibold focus:ring-primary-500 focus:border-primary-500 block w-full p-3 border-white border-opacity-20"
                                            placeholder="Your email*"
                                        />
                                    </div>

                                    {errorMessage !== '' && (<div className="flex bg-red-700 rounded-xl text-white font-semibold p-4">{errorMessage}</div>)}
                                    <button id="submit-form" type="submit" className="btn btn-md bg-white bg-opacity-50 text-white w-full">Subscribe</button>
                                </form>
                            </>)}
                        </div>
                    </>
                )}

            </div>
        </div>
    )
}

export default NewsletterSignup;