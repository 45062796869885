import Head from 'next/head';
import { useContext } from 'react';
import SeoContext from '../lib/seo-context';
import getMedia from '../lib/media';
import { useGlobalContext } from '../lib/global-context';
const Seo = () => {
    const { metaTitle, metaDescription, metaImage, keywords, canonicalURL, structuredData, type } = useContext(SeoContext);
    const shareImage = getMedia(metaImage);
    const { state } = useGlobalContext();
    return (
        <>
            <Head>
                {metaTitle && (
                    <>
                        <title>{metaTitle}</title>
                        <meta property="og:title" content={metaTitle} key="og:title" />
                        <meta name="twitter:title" content={metaTitle} />
                    </>
                )}
                {metaDescription && (
                    <>
                        <meta name="description" content={metaDescription} />
                        <meta name="twitter:description" content={metaDescription} />
                        <meta property="og:description" content={metaDescription} key="og:description" />
                    </>
                )}
                {shareImage && (
                    <>
                        <meta property="og:image" content={shareImage} />
                        <meta name="twitter:image" content={shareImage} />
                        <meta name="image" content={shareImage} />
                    </>
                )}
                <meta property="og:type" content={type ? type : 'website'} />
                <meta name="keywords" content={keywords} />

                <meta name="twitter:card" content="summary_large_image" key="twitter:card" />
                <meta property="og:url" content={canonicalURL} key="og:url" />
                <meta name="theme-color" content="#1e77cb" />
                <link rel="canonical" href={canonicalURL} />
                <link rel="icon" href="/favicon.ico" />

                {structuredData && (
                    <script
                        id="structured-data"
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
                    />
                )}

                <link rel="alternate" type="application/rss+xml" title={`${state.company.name} RSS Feed`} href="/rss.xml" />
                <link rel="alternate" type="application/atom+xml" title={`${state.company.name} ATOM Feed`} href="/atom.xml" />
                <link rel="alternate" type="application/feed+json" title={`${state.company.name} JSON Feed`} href="/rss.json" />

            </Head>
        </>
    );
};

export default Seo;
