import Image from 'next/image';
import { useGlobalContext } from '../lib/global-context';
import GetMedia from '../lib/media';

export default function Photo({ quality, image, layout, priority, objectFit }) {
    if (!image) {
        return;
    }
    if (!quality) quality = 85;
    const { alternativeText, width, height } = image.data.attributes;

    return (
        <>
            {layout === 'fill' && (
                <Image
                    src={GetMedia(image)}
                    objectFit={objectFit ? objectFit : 'cover'}
                    alt={alternativeText || ''}
                    layout={layout}
                    priority={priority}
                    quality={quality}
                    className="object-fill"
                ></Image>
            )}

            {layout !== 'fill' && (
                <Image
                    width={width}
                    height={height}
                    objectFit={objectFit ? objectFit : 'contain'}
                    src={GetMedia(image)}
                    alt={alternativeText || ''}
                    layout={layout}
                    quality={quality}
                    priority={priority}
                ></Image>
            )}
        </>
    );
}
