import Navbar from './navbar';
import Footer from './footer-v2';
import Seo from './seo';

import I18nContext from '../lib/i18n-context';

export default function Layout({ languageData, footerClass, children }) {
    return (
        <I18nContext.Provider value={languageData}>
            <Seo />
            <Navbar />
            <main>{children}</main>
            <Footer className={footerClass} />
        </I18nContext.Provider>
    );
}
